@use '../includes' as *;

$cpb1: 530px;
$cpb2: 650px;
$cpb3: 750px;

.video_detail_thumbnail_container {
  container-type: inline-size;
  width: clamp(288px, 100%, 836px);
}

.video_detail_thumbnail {
  display: grid;
  grid-template-areas:
    'mezzanineLink title       button'
    'description   description .';
  grid-template-columns: 1fr 2fr 21px;
  align-items: center;
  gap: $g4 $g8;

  @container (min-width: #{$cpb1}) {
    grid-template:
      'mezzanineLink title      ' auto
      'mezzanineLink description' 40px
      'mezzanineLink button     ' auto;
    grid-template-columns: 1fr 2fr;
    gap: $g8 $g16;
  }

  @container (min-width: #{$cpb3}) {
    &:has(.video_detail_accessibility_icons:not(:empty)) {
      grid-template:
        'mezzanineLink title      ' auto
        'mezzanineLink a11yicons  ' auto
        'mezzanineLink description' 40px
        'mezzanineLink button     ' auto;
      grid-template-columns: 1fr 2fr;
    }

    gap: $g8 $g20;
  }

  // the mezzanine link is a direct child of the thumbnail
  [class*='video_mezzanine_link_wrapper'] {
    grid-area: mezzanineLink;
    align-self: start;
  }

  // my list button
  > button {
    grid-area: button;
    align-self: center;
    width: 21px;
    border-width: 1px;

    @container (min-width: #{$cpb2}) {
      align-self: start;
      width: 36px;
      border-width: 2px;
    }
  }
}

.video_detail_show_title {
  display: block;
  margin-block-end: $g4;
  font-size: $f10;
  font-weight: $bold;
  text-transform: uppercase;
}

.video_title {
  @include h4;

  grid-area: title;
  align-self: center;

  @container (min-width: #{$cpb2}) {
    align-self: end;
  }

  .video_detail_thumbnail:has(.parent_link) & {
    align-self: start;

    @container (min-width: #{$cpb2}) {
      align-self: center;
    }
  }
}

.video_detail_accessibility_icons {
  display: none;
  grid-area: a11yicons;
  color: $pebble;

  @container (min-width: #{$cpb3}) {
    &:not(:empty) {
      display: block;
    }
  }
}

.video_description {
  @include body-copy-3($pebble);
  @include typography-truncate(2);

  grid-area: description;
  margin-block-end: $g4;

  @container (min-width: #{$cpb3}) {
    @include body-copy-1($pebble);
  }
}
