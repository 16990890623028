@use '../includes' as *;

.passport_hover_overlay {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  padding: $g16;
  background-color: rgba($midnight, 0.4);
  pointer-events: none;
}

.passport_hover_overlay_text {
  @include body-copy-3($fog);

  margin-block-end: 0.5cqh;
  text-align: center;
}

.passport_button {
  display: inline-flex;
  padding-inline: $g16;
  pointer-events: auto;
}
