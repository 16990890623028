@use '../includes' as *;
@use '@/components/ShowRow/ShowRowSplide.module.scss' as splide;

.continue_watching_row {
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  [class*='splide__track'] {
    // allows for expanded poster to be visible on hover
    overflow: visible;
  }

  &:has([class*='splide__track']:hover) {
    [class*='splide__slide']:not(:hover) {
      [class*='continue_watching_show_poster'] {
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: rgba($midnight, 0.5);
          pointer-events: none;
        }
      }
    }
  }
}

.continue_watching_row__title {
  @include h3;

  display: inline-block;
  margin-block-end: $g12;
}

// Using the double class hack here to increase
// specificity otherwise the splide__arrow class
// overrides this custom arrow styling
.continue_watching_row__splide_arrow.continue_watching_row__splide_arrow {
  @include splide-arrow;
}
