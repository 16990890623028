@use '../includes' as *;

.progress {
  height: clamp(3px, 4%, 4px);
  appearance: none;

  &::-webkit-progress-bar {
    border-radius: 0 6px 6px 0;
    background-color: rgba($white, 0.4);
  }

  &::-webkit-progress-value {
    border-radius: 6px 0 0 6px;
    background-color: $coral;
  }
}
