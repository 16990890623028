@use '../includes' as *;

@keyframes foo {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.video_mezzanine_link_wrapper {
  @include image-thumbnail-link;

  & {
    container-type: inline-size;
  }

  progress {
    position: absolute;
    inset: auto 4% 8%;
    width: 92%;
  }

  .passport_cta {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      .passport_cta {
        // we can only display the passport CTA if the thumnbail
        // is 235px or wider - any narrower than that, and the CTA text
        // collides with badges
        @container (min-width: 235px) {
          display: flex;
          animation: foo var(--duration) forwards;
        }
      }
    }
  }
}

.video_mezzanine_link_now_playing {
  outline: var(--border-thickness) solid $coral;
}

.video_mezzanine_link_passport_badge {
  position: absolute;
  inset: 0;
  width: 22%;
  // fixes a strange hover behavior when the mezzanine link
  // is in a carousel
  height: 0;
}

.video_mezzanine_link_badge {
  position: absolute;
  inset: 4% 3% auto auto;
}
